<template>
  <PageContent>
    <h1 class="page-title heading-color tw-text-center tw-my-7">
      {{ config.siteName }} App
    </h1>
    <OpenSnowApp />
  </PageContent>
</template>

<script>
export default {
  name: 'AppPage',

  setup() {
    const config = useRuntimeConfig().public;
    return { config };
  },
};
</script>
